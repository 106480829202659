import React from 'react';
import { useLoaderStyles } from './SrLoader.style';

const SrLoader = () => {
  const classes = useLoaderStyles();

  return (
    <div className={classes.loaderWrapper}>
      <div className={`${classes.circle} ${classes.circle1}`} />
      <div className={`${classes.circle} ${classes.circle2}`} />
      <div className={`${classes.circle} ${classes.circle3}`} />
      <div className={`${classes.circle} ${classes.circle4}`} />
    </div>
  );
};

export default SrLoader;
