import { makeStyles } from '@material-ui/core';

const useLoaderStyles = makeStyles((theme) => ({
  loaderWrapper: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circle: {
    borderRadius: '100%',
    backgroundColor: theme.palette.black,
    width: 0,
    height: 0,
    opacity: 1,
    animationName: '$circle',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    position: 'absolute',
  },
  circle1: {
    animationDelay: '0s',
  },
  circle2: {
    animationDelay: '0.5s',
  },
  circle3: {
    animationDelay: '1s',
  },
  circle4: {
    animationDelay: '1.5s',
  },
  '@keyframes circle': {
    from: {
      width: 0,
      height: 0,
      opacity: 1,
    },
    to: {
      width: '45vh',
      height: '45vh',
      opacity: 0,
    },
  },
}));

export { useLoaderStyles };
