import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/montserrat';
import 'styles/style.css';

import {
  StylesProvider,
  createGenerateClassName,
  ThemeProvider,
} from '@material-ui/core/styles';

import { BrowserRouter } from 'react-router-dom';
import Routes from 'route/routes';

// import reportWebVitals from './reportWebVitals';
import senseloafTheme from 'styles/theme';

const generateClassName = createGenerateClassName({
  productionPrefix: 'sr',
  disableGlobal: true,
});

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={senseloafTheme}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
