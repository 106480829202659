import SrLoader from 'core/SrLoader/SrLoader';
import React, { useLayoutEffect, Suspense } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import { uuid } from 'utils/uuid';
import { ROUTES } from './routeList';

const Error404 = React.lazy(() => import('pages/Error404'));

const Routes = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    // scroll page to top on route change
    window.scrollTo(0, 0);

    // set tab name
    const route = ROUTES.find((r) => r.path === location.pathname);

    if (route && route.title) {
      document.title = route.title;
    }
  }, [location.pathname]);

  return (
    <div>
      <Suspense fallback={<SrLoader />}>
        <Switch location={location} key={location.pathname}>
          {ROUTES.map(({ path, component }) => (
            <Route key={uuid()} exact path={path} component={component} />
          ))}
          <Route key={uuid()} component={Error404} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default Routes;
