import { createTheme } from '@material-ui/core';

const senseloafTheme = createTheme({
  palette: {
    background: {
      default: '#fff',
    },
    black: '#000', // primary
    white: '#fff', // seconday
    text: {
      black: '#000',
      black_90: 'rgba(0, 0, 0, 0.9)',
      black_80: 'rgba(0, 0, 0, 0.8)',
      black_70: 'rgba(0, 0, 0, 0.7)',
      black_60: 'rgba(0, 0, 0, 0.6)',
      black_50: 'rgba(0, 0, 0, 0.5)',
      black_40: 'rgba(0, 0, 0, 0.4)',
      black_30: 'rgba(0, 0, 0, 0.3)',
      black_20: 'rgba(0, 0, 0, 0.2)',
      black_10: 'rgba(0, 0, 0, 0.1)',
      white: '#fff',
      white_90: 'rgba(255, 255, 255, 0.9)',
      white_80: 'rgba(255, 255, 255, 0.8)',
      white_70: 'rgba(255, 255, 255, 0.7)',
      white_60: 'rgba(255, 255, 255, 0.6)',
      white_50: 'rgba(255, 255, 255, 0.5)',
      white_40: 'rgba(255, 255, 255, 0.4)',
      white_30: 'rgba(255, 255, 255, 0.3)',
      white_20: 'rgba(255, 255, 255, 0.2)',
      white_10: 'rgba(255, 255, 255, 0.1)',
      error: '#d40000',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});

export default senseloafTheme;
