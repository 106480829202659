import React from 'react';

const HomePage = React.lazy(() => import('pages/HomePage'));
const ProductPage = React.lazy(() => import('pages/Product'));
const GetFreeCreditsPage = React.lazy(() => import('pages/GetFreeCredits'));
const AboutUsPage = React.lazy(() => import('pages/AboutUs'));
const ContactUsPage = React.lazy(() => import('pages/ContactUs'));
const DemoRegistrationPage = React.lazy(() => import('pages/DemoRegistration'));
const UsecasesPage = React.lazy(() => import('pages/Usecases'));

export const ROUTES = [
  {
    path: '/',
    component: HomePage,
    title: 'Senseloaf AI',
  },
  {
    path: '/product',
    component: ProductPage,
    title: 'Senseloaf AI - Product',
  },
  {
    path: '/product/:productType',
    component: ProductPage,
    title: 'Senseloaf AI - Product',
  },
  {
    path: '/get-free-trial',
    component: GetFreeCreditsPage,
    title: 'Senseloaf AI - Get Free Credits',
  },
  {
    path: '/about-us',
    component: AboutUsPage,
    title: 'Senseloaf AI - About us',
  },
  {
    path: '/contact-us',
    component: ContactUsPage,
    title: 'Senseloaf AI - Contact us',
  },
  {
    path: '/demo-registration',
    component: DemoRegistrationPage,
    title: 'Senseloaf AI - Demo Registration',
  },
  {
    path: '/use-case',
    component: UsecasesPage,
    title: 'Senseloaf AI - Usecase',
  },
  {
    path: '/use-case/:usecaseType',
    component: UsecasesPage,
    title: 'Senseloaf AI - Usecase',
  },
];
